
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";

export default defineComponent({
  name: "report-claims",
  components: {},
  setup() {
    const user = computed(() => {
      return store.getters.currentUser;
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Report Claims", ["Claims"]);
    });

    return { user };
  },
  created() {
    //Set page title
    document.title = "Report Claims | " + process.env.VUE_APP_TITLE;
  },
});
